@font-face {
    font-family: 'press_start_2pregular';
    src: url('./fonts/pressstart2p-webfont.woff2') format('woff2'),
        url('./fonts/pressstart2p-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitoregular';
    src: url('./fonts/nunito-regular-webfont.woff2') format('woff2'),
        url('./fonts/nunito-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'nunitobold';
    src: url('./fonts/nunito-bold-webfont.woff2') format('woff2'),
        url('./fonts/nunito-bold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

html {
    min-height: 100%;
}

body {
    background: url('assets/bg.jpg') !important;
    /* background: url('assets/pixels_background.png'), linear-gradient(180deg, #357ECB 0%, #6937E0 100%) !important; */
    background-size: cover !important;
    background-repeat: no-repeat !important;
    overflow-x: hidden;
    font-family: "nunitoregular" !important;
}

/* slick stuff */
.slick-dots {
    bottom: 20px !important;
}